import React, {useState} from "react";
import {
	AlertCircleIcon, formatter, parseLocalDate,
	SbCardCheckbox, SbLinkButton,
	SbTypography,
	TranslationProvider
} from "@surebase/shared-component-library";
import {
	Card,
	CardActions, CardHeader,
	Divider,
	Stack,
	useTheme
} from "@mui/material";
import {Settings} from "../../global/Settings";
import {grey} from "@mui/material/colors";
import {DateHelper} from "../../helpers/DateHelper";
import {Invoices} from "../../services/invoices/graphql/Invoices";

export interface OutstandingInvoicesCardProps {
	readonly invoices: Invoices.Invoice[];
	readonly width?: string;
	readonly invoicesLink?: string;
	
	onPayClick(invoiceIds: string[]): void;
}

export function OutstandingInvoicesCard(props: OutstandingInvoicesCardProps) {
	const theme = useTheme();
	const [selectedInvoiceIds, setSelectedInvoiceIds] = useState<string[]>([]);
	
	const getCardButtonDetails = (invoice: Invoices.Invoice) => {
		return [
			formatter.currency(invoice.amountOutstandingInvoice),
			invoice.invoiceNumber + " • " + formatter.date(parseLocalDate(invoice.bookingDate), DateHelper.properShortDateFormat)
		];
	};
	
	const handleInvoiceSelection = (itemId: string, isChecked: boolean) => {
		let newSelection = [];
		if (isChecked) {
			newSelection = [...selectedInvoiceIds, itemId];
		} else {
			const index = selectedInvoiceIds.indexOf(itemId);
			if (index === -1)
				return;
			
			newSelection = [
				...selectedInvoiceIds.slice(0, index),
				...selectedInvoiceIds.slice(index + 1)
			];
		}
		
		setSelectedInvoiceIds(newSelection);
	};
	
	const handlePayInvoicesClick = () => {
		props.onPayClick(selectedInvoiceIds);
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Card variant={"outlined"}>
			<CardHeader style={{backgroundColor: "#FFFFFF", padding: "16px", gap: "24px"}}
				avatar={(
					<Stack gap={2} alignItems={"center"}
						direction={"row"}>
						<AlertCircleIcon color={"primary"} width={24} height={24}/>
						<SbTypography variant={"cardButtonTitle1"}>
							Outstanding invoices
						</SbTypography>
					</Stack>
				)}>
			</CardHeader>
			<Divider color={grey[300]}/>
			<CardActions style={{padding: "16px", gap: "16px"}}>
				<Stack gap={2} style={{width: "100%"}}>
					{props.invoices.length === 0 && (
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							No outstanding invoices
						</SbTypography>
					)}
					{props.invoices.length !== 0 && (
						<SbTypography variant={"cardButton2"} color={theme.palette.text.secondary}>
							Here you can view your payment summary and pay any outstanding invoices directly
						</SbTypography>
					)}
					
					{props.invoices.map((invoice, index) => (
						<SbCardCheckbox key={index}
							cardContainerStyle={{height: "auto", width: "100%", borderColor: grey[300]}}
							buttonStyle={{padding: "8px"}}
							contentGap={"4px"}
							details={getCardButtonDetails(invoice)}
							itemId={invoice.id}
							isChecked={selectedInvoiceIds.indexOf(invoice.id) > -1}
							onCheckChange={handleInvoiceSelection}/>
					))}
					
					{props.invoicesLink && (
						<SbLinkButton to={props.invoicesLink} ButtonProps={{
							variant: selectedInvoiceIds.length === 0 ? "contained" : "outlined",
							size: "large",
							color: "primary",
							style: {padding: "10px 18px", borderRadius: "4px"}
						}}>
							<SbTypography variant={"buttonLarge"}>View all invoices</SbTypography>
						</SbLinkButton>
					)}
				</Stack>
			</CardActions>
		</Card>
	</TranslationProvider>;
}
