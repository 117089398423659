import {
	authorizedByPermission,
	fullWidth,
	SbButton,
	SbGraphQlDataGrid,
	SbPageHeader,
	SbTypography,
	SearchSmIcon,
	TranslationProvider,
	useDateTimeColumn,
	useLinkIconColumn,
	useStringColumn
} from "@surebase/shared-component-library";
import {InputAdornment, OutlinedInput, Stack, useTheme} from "@mui/material";
import {Settings} from "../../global/Settings";
import React from "react";
import {permissions} from "../../global/Permissions";
import {CloudDownload, FilterList} from "@mui/icons-material";
import { Dossiers } from "../../services/documents/Dossiers";
import {queryDossiers} from "../../services/documents/DossiersQueries";
import {graphQlServices} from "../../services/GraphQlServices";
import {GridActionsCellItem, GridColDef, GridToolbarContainer} from "@mui/x-data-grid-pro";
import { useParams } from "react-router-dom";
import {DossierHelper} from "../../helpers/DossierHelper";

export const DocumentsPage = authorizedByPermission(() => {
	const theme = useTheme();
	const id = useParams()["id"] ?? "";
	
	type E = Dossiers.Dossier;
	const columns: GridColDef<E>[] = [
		useStringColumn<E>("id"),
		useStringColumn<E>("documentDescription", { headerName: "Document description" }),
		useDateTimeColumn<E>("effectiveDate", { headerName: "Effective date" }),
		useLinkIconColumn<E>(() => "", { headerName: "Download", renderCell: (params) => <GridActionsCellItem label={"Download"} icon={<CloudDownload/>} onClick={() => DossierHelper.downloadDossier(params.id as string)} />})
	];
	
	const customToolbar = () => {
		return <GridToolbarContainer style={{width: "100%", height: "32px", padding: "4px 0px 0px 4px", marginBottom: "24px"}}>
			<Stack gap={2} direction={"row"}>
				<SbButton ButtonProps={{
					size: "medium",
					variant: "outlined",
					startIcon: <FilterList/>,
					style: {
						color: theme.palette.text.primary,
						backgroundColor: "#FFF",
						border: "1px solid #E0E0E0"
					}
				}}>
					Filter
				</SbButton>
				<OutlinedInput
					size={"small"}
					placeholder={"Search"}
					style={{
						backgroundColor: "#FFF",
					}}
					startAdornment={
						<InputAdornment position={"start"}>
							<SearchSmIcon />
						</InputAdornment>
					}
				/>
			</Stack>
		</GridToolbarContainer>;
	};
	
	return <TranslationProvider name={Settings.languageModule}>
		<Stack gap={2} style={{...fullWidth}}>
			<Stack gap={1}>
				<SbPageHeader pageName={"Documents"} overviewRoute={"/"} disableBreadcrumbs={false}/>
				<SbTypography style={{
					fontWeight: "400",
					fontSize: "16px",
					lineHeight: "24px",
					letterSpacing: "0.15px",
					color: theme.palette.text.secondary
				}}>Lorem ipsum dolor sit amet consectetur. Diam sit fermentum nisl dictumst pellentesque mi duis ut.</SbTypography>
			</Stack>
			<SbGraphQlDataGrid<Dossiers.Queries, Dossiers.Dossier>
				query={queryDossiers}
				graphFunction={"dossiers"}
				client={graphQlServices.dossierServices}
				queryVariables={
					{
						where: {
							party: {
								some: {
									refKey: {
										eq: id
									}
								}
							}
						}
					}
				}
				getRowId={it => it.id}
				rowsPerPageOptions={[5]}
				initialPageSize={25}
				pageSizeOptions={[25]}
				columns={columns}
				gridHeight={500}
				initialState={{
					columns: {
						columnVisibilityModel: {
							id: false
						}
					}
				}}
				infiniteLoading={true}
				slots={{
					toolbar: customToolbar
				}}
			/>
		</Stack>
	</TranslationProvider>;
}, [permissions.dossiersRead]);
