import React from "react";
import {
	createMenuItem,
	FileCheck01Icon,
	Home02Icon,
	isAuthorizedByPermission,
	ListIcon,
	SbMain,
	SbPage, sharedConfiguration,
	Users02Icon,
	userSessionProvider,
	Wallet04Icon
} from "@surebase/shared-component-library";
import {HomePage} from "../../pages/HomePage";
import {Route} from "react-router-dom";
import {MessageBoxPage} from "../../pages/MessageBox/MessageBox";
import {SettingsPage} from "../../pages/settings/SettingsPage";
import {ExamplePage} from "../../pages/example/ExamplePage";
import { InvoicesPage } from "../../pages/invoices/InvoicesPage";
import { DocumentsPage } from "../../pages/documents/DocumentsPage";

export function Main() {
	return <SbMain
		routes={<>
			<Route index element={<SbPage key={"home"} child={<HomePage/>}/>}/>
			<Route path={"messages"} element={<SbPage key={"messages"} child={<MessageBoxPage/>}/>}/>
			<Route path={"settings"} element={<SbPage key={"settings"} child={<SettingsPage/>}/>}/>
			<Route path={"invoices/:id"} element={<SbPage child={<InvoicesPage/>}/>}/>
			<Route path={"documents"}>
				<Route index element={<SbPage child={<DocumentsPage/>}/>}/>
				<Route path={":id"} element={<SbPage child={<DocumentsPage/>}/>}/>
			</Route>
			{/* the example page is only for showcasing components and verifying that they are implemented according to the design */}
			{/* this is only needed on dev */}
			{sharedConfiguration.environment.kiziStage === "kizidev"
				? <Route path={"example"} element={<SbPage key={"example"} child={<ExamplePage/>}/>}/>
				: undefined}
		</>}
		translationModules={["SureBase"]}
		menuItems={[...menuItems()]}
		newsFeedProps={{
			visiblePaths: ["/", "/customers/*", "/contracts/*", "/claims/*"],
			pathFormat: {
				"customers": "/customers/customerId",
				"contracts": "/contracts/customerId/contractId",
				"claims": "/claims/customerId/claimId"
			}
		}}
	/>;
}

function* menuItems() {
	yield createMenuItem(Home02Icon, "Overview", "/");
	yield createMenuItem(Wallet04Icon, "Financial", "/management-info/financial", [
		createMenuItem(undefined, "Current account", "/management-info/financial/current-account"),
		createMenuItem(undefined, "Renewal Overview", "/management-info/financial/renewal-overview"),
		createMenuItem(undefined, "One-Time bookings", "/management-info/financial/one-time-bookings"),
	]);
	yield createMenuItem(Users02Icon, "Customers", undefined, [
		createMenuItem(undefined, "Overview", "/customers"),
		createMenuItem(undefined, "Create", "/customers/create"),
	]);
	yield createMenuItem(FileCheck01Icon, "Tasks", undefined, [
		createMenuItem(undefined, "Overview", "/tasks"),
		createMenuItem(undefined, "Create", "/tasks/new"),
	]);
	
	const workQueueItems = [...workQueueMenuItems()];
	if (workQueueItems.length > 0) {
		yield createMenuItem(ListIcon, "Work Queues", "/work-queues", workQueueItems);
	}
}

function* workQueueMenuItems() {
	const srn = userSessionProvider.userSession?.user?.srn;
	if (srn && srn.startsWith("srn:risk:tenant::")) {
		if (isAuthorizedByPermission("WorkQueues:WorkItemsRead")) {
			yield createMenuItem(undefined, "Work List", "/work-queues/work-list");
			yield createMenuItem(undefined, "Work Items", undefined, [
				createMenuItem(undefined, "All items", "/work-queues/all-items"),
				createMenuItem(undefined, "Completed items", "/work-queues/completed-items"),
				createMenuItem(undefined, "Parked items", "/work-queues/parked-items"),
				createMenuItem(undefined, "Scheduled items", "/work-queues/scheduled-items"),
			]);
		}
		
		if (isAuthorizedByPermission("WorkQueues:WorkQueuesRead"))
			yield createMenuItem(undefined, "Queues", "/work-queues/queues");
		
		if (isAuthorizedByPermission("WorkQueues:DashboardRead"))
			yield createMenuItem(undefined, "Dashboard", "/work-queues/dashboard");
		
		if (isAuthorizedByPermission("WorkQueues:AccountsManage"))
			yield createMenuItem(undefined, "Accounts", "/work-queues/accounts");
		
		if (isAuthorizedByPermission("WorkQueues:WorkItemsImport"))
			yield createMenuItem(undefined, "Upload", "/work-queues/upload-work");
	}
}
