export class DateHelper {
	
	static getTodayDateTime(): string {
		const now = new Date();
		const year = now.getFullYear();
		const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
		const day = String(now.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}T00:00`;
	}
	
	static calculateDateDifference(start: Date, end: Date): { days: string; hours: string } {
		// Calculate the difference in milliseconds
		const differenceMs = end.getTime() - start.getTime();
		
		// Convert milliseconds to days and remaining hours
		const days = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
		const hours = Math.floor((differenceMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		return {days: days.toString().padStart(2, "0"), hours: hours.toString().padStart(2, "0")};
	}
	
	static properShortDateTimeFormat: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
		hour12: false
	};
	
	static properShortDateFormat: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
	};
}
